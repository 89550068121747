.hcont {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  padding: 15px;
}

::-webkit-scrollbar {
  display: none;
}

.ttext {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #8f9bba;
}

.nmonth {
  width: 16px;
  height: 16px;
  background: #7edd62;
  margin-right: 10px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.withprice {
  padding-top: 25px;
  padding-bottom: 25px;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #8f9bba;
}

.unprice {
  display: flex;
  align-items: center;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  letter-spacing: -0.02em;

  color: #bccccc;
}

.uprice {
  display: flex;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
  color: #7edd62;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.upicon {
  margin-right: 5px;
}

.key {
  position: relative;
}

.kcurrent,
.kprojected {

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #bccccc;
  display: flex;
  align-items: center;
}

.kccolor {
  background: #FFBA4D;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.kpcolor {
  background: rgba(255, 186, 77, 0.6);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.kcont {
  width: fit-content;
}

.Grow {
  margin: 20px;
  margin-left: 30px;
  padding-top: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  padding-bottom: 20px;
}

.tbars {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: #f4f7fe;
  border-radius: 10px;
}