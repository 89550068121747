.table {
  border-collapse: collapse;
  border: none;
  width: 100% !important;
  min-width: 100% !important;
  flex: 1;
}

.tableRowHeader {
  background-color: blue;
  /* transition: all 0.25s ease; */
  /* border-radius: 10px; */
  /* width: 100% !important; */
}

.tableHeader {
  width: 10%;
  background-color: #ffffff;
  padding: 12px;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  color: black;
  border-bottom: 2px solid #eaecee;
}

.tableHeader:first-child {
  border-top-left-radius: 0px;
}

.tableHeader:last-child {
  border-top-right-radius: 0px;
}

.tableRowItems {
  cursor: auto;
  width: 100%;
  min-width: 100%;
}

.tableRowItems:nth-child(even) {
  background-color: #F1F4FA;
}

.tableCell {
  padding: 12px;
  font-size: 12px;
  color: black;
  font-weight: 300;
  text-align: left;
  /* width: 100%; */
}

.dtoggle::after {
  display: none !important;
}

.dtoggle::after {
  display: none !important;
}

.copy_icon {
  cursor: pointer;
  margin-left: 6px;
  color: grey;
}