.yam {
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: #f7f9fc;
  height: 75px;
  padding: 4px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.ttext {
  width: 139px;
  height: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  color: #1e2727;
  margin-left: 10px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.nav_left {
  width: 50%;
}

.nav_right {
  display: flex;
  width: 50%;
  text-align: right;
}

.gear {
  margin: 10px;
  display: inline-block;
  color: #ffba4d;
}

.name {
  font-style: normal;
  font-weight: 400;
  font-size: 12.64px;
  line-height: 17px;

  /* Light Version/Text */

  color: #06152b;
  margin-left: 20px;
  margin-top: -20px;
}

.ditem {
  font-size: 12.64px;
}