@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #ff0000;
    /* Change to desired color */
}

iframe {
    display: none;
}

ion-popover {
    --width: 420px;
}