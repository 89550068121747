.dmen {
  background-color: #1e2727;
  border-radius: 0px 0px 0px 10px;
}

.nditem {
  display: flex;
  background-color: #1e2727;
  color: #ffffff;
  align-items: center;
  /* border-bottom: 1px solid #7edd62; */
}

.nditem:hover {
  display: flex;
  background-color: #1e2727;
  color: #ffba4d;
  align-items: center;
}

.nditem:hover .nsvg path {
  fill: #ffba4d;
}

.dtoggle::after {
  display: none !important;
}

.customeClose {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #1e2727;
  font-size: x-large;
  cursor: pointer;
}

.mhead {
  border-bottom: #7d7d7d 1px solid;
}

.finput {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #eaecee;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  color: #1a1d20;
}

.sbtn {
  width: 100%;
  height: 40px;
  background: #7edd62;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  color: white;
  border: none;
  cursor: pointer;
}

.sbtn:hover {
  color: #1a1d20;
  background: #7edd62;
}

.navLink {
  text-decoration: none;
  margin-right: 8px;
}

.navLink:active {
  text-decoration: underline;
}


.nicont {}