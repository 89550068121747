* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.contai {
  display: block;
  width: fit-content;
  position: sticky;
  top: 0;
  left: 0;
  float: left;
  height: 100vh;
}

main {
  width: 100%;
  padding: 20px;
}

.sbsidebar {
  position: relative;
  background: #1e2727;
  color: #fff;
  height: 100%;
  transition: all 0.5s;
}

.sbtop_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.sblogo {
  font-size: 30px;
}

.sbbars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.sblink {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
}

.sblink:hover {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  transition: all 0.5s;
  /* border-right: 2px solid #7edd62; */
}

.sblink:hover .sbicon .svg path {
  fill: #7edd62;
  color: #7edd62;
}

.sbactive {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  transition: all 0.5s;
  /* border-right: 2px solid #7edd62; */
}

.sblink:active {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  transition: all 0.5s;
  border-right: 2px solid #7edd62;
}

.sbactive .sbicon .svg path {
  fill: #7edd62;
  color: #7edd62;
}

.sbicon,
.sblink_text {
  font-size: 20px;
}

/* 
.cicon{
    width: 50px;
    height: 50px;
} */
.sbfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 15px;
  text-align: center;
  font-size: 6px;
  color: #fff;
  background: #1e2727;
}