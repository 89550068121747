.flabel {

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  color: #06152b;
}



.fcontrol {
  box-sizing: border-box;
  width: 174px;
  height: 40px;
  mix-blend-mode: normal;
  border: 1px solid #eaecee;
  border-radius: 10px;
}

.fgroup {
  width: fit-content;
  display: inline-block;
}

.fsearch {
  position: relative;
}

.sbtn {
  position: absolute;
  top: 28px;
  right: 15px;
  width: 93px;
  height: 40px;
  background: #7edd62;
  border-radius: 10px;
  float: right;
}

.table_col {
  padding: 15px;
  /* width: 100%; */
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  margin: 15px;
}

.ttitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */
  color: #1a1d20;
  width: 100%;
  text-align: left;
}

.tcont1 {
  height: 100%;
  width: 100%;
}

.tcard {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  padding-right: 20px;
}

::-webkit-scrollbar {
  display: none;
}

table {
  display: inline-block;
  border-collapse: collapse;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

th,
td {
  /* height: 68.17px; */
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 13.3523px;
  line-height: 18px;
}

th {
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f1f4fa;
}

.td_icon {
  position: relative;
  padding-right: 15px;
}

.copy_icon {
  position: absolute;
  top: 5px;
  right: 0px;
}

.abtn {
  width: 93px;
  height: 40px;
  background-color: #7edd62;
  border-radius: 10px;
  float: right;
  margin: 5px;
}

.drop {
  width: 10px;
  height: 10px;
  margin-top: -15px;
}

.rshow {
  font-size: 13.5px;
}