.main-container {}

.card-container {}

.card {
  display: inline-block;
  background: #ffffff;
  border-radius: 20px;
  max-width: 576px;
  width: 45%;
  min-width: 250px;
  margin: 4px;
}

.card-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */

  color: #1a1d20;
}

.icon {
  position: absolute;
  right: 2px;
}

.card-subtitle {

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  color: #7d7d7d;
}

hr {
  border: 1px solid #7d7d7d;
}

.text-in {
  mix-blend-mode: normal;
  border: 1px solid #7d7d7d;
  border-radius: 10px;
  /* height: 40px; */
}