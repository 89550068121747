.col-container {
  height: 100vh;
}

.left-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.s-title {
  font-size: 36px;
  line-height: 56px;
  font-weight: 700;

}

.sub-title {

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #a3aed0;
  margin-bottom: 10px;
}

.submit-btn {
  width: 100%;
  background-color: #7edd62;
  border-radius: 16px;
  padding: 10px, 8px, 10px, 8px;
  gap: 10px;
}

.pass-link {
  color: #ffba4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

}

.right-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1e2727;
}

.right-text {
  text-align: center;
  background-color: #1e2727;
  color: #ffffff;
}